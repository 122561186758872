import React,{Component} from 'react';
import './hero.css';
import Header from './../../components/header/header';
import Vector from './../../Vector.png';
import Button from '../../components/buttons/button';
import IMG from '../../back.jpeg';

class HeroSection extends Component{
render(){
    return(
        <section className="bg-header" style={{backgroundImage:`url(${IMG})`,backgroundSize:"100% 100%"}}>
        <Header/>
        <div className="hero-content">
        <div className="hero-text">
                    We create 
            digital experiences &
            digital ecosystems
            <br/>
            <Button title="Get started"/>
        </div>
        <div className="hero-programmer">
        <img className="programmer-img" src={Vector} alt="programmer"/>
        <span className="programmer-job">From ideas to reality</span>    
        </div>
        </div>
        </section>
    )
}
}


export default HeroSection;