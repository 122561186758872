import React, { Component } from 'react';
import Card from '../../components/cards/Card';
import './contact.css';
import {Input,TextInput} from '../../components/input/input';

class ContactUs extends Component{

    render(){
        return(
            <div className="contact-section">
                <h1>Contact Us</h1>                
                <Card width="754px" height="365px" shadow="0 1px 4px 0 rgba(243,202,62,0.5)">
                <form>
                <Input
                type="text"
                name="name"
                placeholder="Full Name"
                />
                <div>
                <Input
                type="text"
                name="email"
                placeholder="Email"
                />
                <Input
                type="text"
                name="phone"
                placeholder="Phone"
                />
                </div>
                <TextInput
                type="text"
                name="message"
                placeholder="Message"
                rows="5"
                />
                <button>
                    Send
                </button>
                </form>
                </Card>
            </div>
        )
    }
}

export default ContactUs;