import React, { Component } from 'react';
import './value.css';
import Card from '../../components/cards/Card';
import BG1 from '../../assets/img/mix-icon-1.png';
import BG2 from '../../assets/img/mix-icon-2.png';
import BG from '../../assets/img/ring-circle.png';

class ValueSection extends Component{
    render(){
        return(
            <div className="values-section" style={{backgroundImage:`url(${BG2}), url(${BG1})`}}>
                <img width="706px" height="756px" src={BG} alt="circle"/>
                <div className="philosophy">
                    <h2>Our Philosophy</h2>
                    <Card width="636px" height="237px" shadow="0 1px 4px 0 rgba(154,112,243,0.58)">
                    <div className="card-content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi 
                    ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
                    in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint 
                    occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </div>
                        </Card>
                    </div> 
                <div className="values">
                    <h2>Our Values</h2>
                    <Card width="636px" height="237px" shadow="0 1px 4px 0 rgba(0,226,153,0.5)">
                        <div className="card-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi 
                    ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
                    in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint 
                    occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </div>
                        </Card>
                    </div> 
            </div>
        )
    }
}

export default ValueSection;