import React,{Component} from 'react';
import './work.css';
import Button from '../../components/buttons/button';
import WorkCard from '../../components/cards/workCards';
import BCM from '../../assets/img/bcm.png';
import Design from '../../assets/img/design.png';
import Scrapper from '../../assets/img/scrapper.png';
import Automation from '../../assets/img/automation.png';
import DottedLine from '../../assets/img/dotted-line.png';

class WorkSection extends Component{
    render(){
        return(
            <section className="work-section" style={{background:`url(${DottedLine})`,backgroundPositionY:"50%",backgroundRepeat:"no-repeat",backgroundSize:"contain"}}>

            <div className="work">
                <div className="work-header">
                    <h3 className="work-title">Our work</h3>
                    <Button
                    title="view all"/>
                </div>
                <div className="work-cards">
                    <WorkCard shadow="0 1px 4px 0 rgba(154,112,243,0.58)" background={BCM} title="Business Case Modeler"/>
                    <WorkCard shadow="0 1px 4px 0 rgba(243,202,62,0.51)" background={Scrapper} title="Scrapper" reverse={true}/>
                    <WorkCard shadow="0 1px 4px 0 rgba(103,223,160,0.6)" background={Automation} title="Automation"/>
                    <WorkCard shadow="0 1px 4px 0 rgba(154,112,243,0.58)"background={Design} title="Designs" reverse={true}/>
                </div>
            </div>
        </section>
        )
    }
}

export default WorkSection;