import React,{Component} from 'react';
import './App.css';
import HeroSection from './containers/heroSection/hero';
import WhatSection from './containers/whatWeDo/what';
import WorkSection from './containers/workSection/work';
import Footer from './containers/footer/footer';
import ValueSection from './containers/valuesSection/valueSection';
import ContactUs from './containers/contactSection/contact';


class App extends Component {
  render() {
    return (
      <div>
      <HeroSection/>
      <div id="about"></div>
      <WhatSection/>
      <div id="works"></div>
      <WorkSection/>
      <ValueSection/>
      <div id="contact"></div>
      <ContactUs/>
      <Footer/>
      </div>
      )
    }
}

export default App;
