import React from 'react';
import './header.css';

const Header = (props)=>{
    return(
        <div className="nav-container">

        <div className="logo_title">
            <a href="/"><span className="logo_cap">W</span><span >eissenschild</span></a>
        </div>
        <div className="menu">
            <ul className="nav-menu">
                <li><a href="#about">About</a></li>
                <li><a href="#works">Works</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>
        </div>    
        </div>
    )
}

export default Header;