import React from 'react';

const Card = (props) => {
    return(
        <div className="basic-card" style={{width:props.width,height:props.height,boxShadow:props.shadow}}>
            {props.children}
        </div>
    )
}

export default Card;