import React from 'react';
import './card.css';


const WorkCard= (props)=>{

    return (
       
            <div className="card-work" style={{boxShadow:props.shadow}} >
            {props.reverse?(
                <React.Fragment>

                <h3>{props.title}</h3>
                <img src={props.background} alt="myImg"/>
                </React.Fragment>
            ):(
                <React.Fragment>
<img src={props.background} alt="myImg"/>
            <h3>{props.title}</h3>
                </React.Fragment>
            )}
        </div>
        )
}

export default WorkCard;