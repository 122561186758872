import React from 'react';
import './input.css';

const Input = props =>{
    return(
        <input className="form-input" type={props.type} name={props.name} placeholder={props.placeholder}/>
    )
}

const TextInput = props=>{
    return (
        <textarea
        className="form-input" type={props.type} name={props.name} placeholder={props.placeholder}
        rows={props.rows}
        />
    )
}

export  {Input,TextInput};