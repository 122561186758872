import React,{Component} from 'react';
import BG from '../../assets/img/footerbg.png';
import './footer.css';

class Footer extends Component{

render(){
    return(
        <div className="footer" style={{backgroundImage:`url(${BG})`}}>
            <div className="footer-text">
            © 2019 Weissenschild | All rights reserved
            </div>
        </div>
    )
}
} 

export default Footer;