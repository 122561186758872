import React,{Component} from 'react';
import './what.css';
import Circle from '../../assets/img/ellipse.png';
import Tri from '../../assets/img/triangle.png'
import Button from '../../components/buttons/button';


class WhatSection extends Component{

    render(){
        return(
            <section className="what-section">
                <div className="what-info">
                    <div className="what-info-imgs">
                         <img className="circle" src={Circle} alt="ellipse-img"/>
                         <img className="tri" src={Tri} alt="tri-img" />
                    </div>
                    <div className="title">
                    What we do
                    </div>
                    <div className="description">
                    Every product must make a statement and we’re ready
                     to create it with holistic approach to design. We believe 
                     that payments is a problem rooted in code, not finance. We 
                     obsessively seek out elegant,
                    <br/>
                    <br/>
                    composable abstractions that enable robust, scalable, flexible integrations.
                    Because we eliminate needless complexity and extraneous details,
                     you can get up and running with Stripe in just a couple of minutes
                    </div>
                    <br/>
                    <br/>
                    <Button title="Learn More"/>
                </div>
                <div className="what-cards">
                    <div className="card market">
                        <div>
                    <div className="card title">Market Analysis</div>
                    <div className="card content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi 
                    ut aliquip ex ea commodo consequat. 
                    </div>
                        </div>

                    </div>

                    <div className="card-combo">
                        <div className="card product">
                            <div>

                        <div className="card title">Product Design</div>
                        <div className="card content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi 
                    ut aliquip ex ea commodo consequat. 
                    </div>
                        </div>
                            </div>
                        <div className="card finance">
                            <div>

                        <div className="card title">Financial Advice</div>
                        <div className="card content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi 
                    ut aliquip ex ea commodo consequat. 
                    </div>
                        </div>
                            </div>
                    </div>
                </div>
            </section>
        )
    }

}

export default WhatSection;